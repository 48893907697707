// extracted by mini-css-extract-plugin
export var addToCartStyle = "product-page-module--addToCartStyle--k+kqu";
export var breadcrumb = "product-page-module--breadcrumb--sGKkD";
export var container = "product-page-module--container--ME-Mn";
export var header = "product-page-module--header--5xHt5";
export var labelFont = "product-page-module--labelFont--y3ioH";
export var metaSection = "product-page-module--metaSection--ujsJv";
export var noImagePreview = "product-page-module--noImagePreview--QUSD1";
export var optionsWrapper = "product-page-module--optionsWrapper--OkGTQ";
export var priceValue = "product-page-module--priceValue--YwMo3";
export var productBox = "product-page-module--productBox--aaPzf";
export var productDescription = "product-page-module--productDescription--YqDmH";
export var productImageList = "product-page-module--productImageList--7qqid";
export var productImageListItem = "product-page-module--productImageListItem--XNLHX";
export var productImageWrapper = "product-page-module--productImageWrapper--9EwmT";
export var scrollForMore = "product-page-module--scrollForMore--tZDnv";
export var selectVariant = "product-page-module--selectVariant--q9MTn";
export var tagList = "product-page-module--tagList--Edla1";
export var visuallyHidden = "product-page-module--visuallyHidden--JahMp";